<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: `${page_route}${id ? '.detalle' : ''}`,
                  params: { rs_service_id: rs_service_id },
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              `${data.folio ? `${data.folio} | ` : ''}${$route.meta.title}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-form ref="form_submit" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card tile :disabled="id ? true : false">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'ASEGURADO'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.name"
                      :rules="rules.textRequired"
                      maxlength="50"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="A. Paterno*"
                      v-model="data.first_surname"
                      :rules="rules.textRequired"
                      maxlength="25"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="A. Materno"
                      v-model="data.text"
                      maxlength="25"
                      type="text"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'SOLICITUD'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="6">
                    <v-text-field
                      label="Correo electrónico"
                      v-model="data.email"
                      :rules="rules.emailNoRequired"
                      maxlength="80"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <v-text-field
                      label="Teléfono"
                      v-model="data.phone"
                      maxlength="10"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      rows="1"
                      label="Detalle"
                      v-model="data.detail"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'DOCUMENTOS'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" v-for="(v, i) in data.documents" :key="i">
                    <v-row v-if="v.active">
                      <v-col cols="12" xs="12" md="5">
                        <v-text-field
                          label="Descripción"
                          v-model="v.description"
                          maxlength="100"
                          type="text"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-file-input
                          label="Archivo digital*"
                          v-model="v.file"
                          accept="image/*,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
                          :rules="id ? [] : rules.required"
                          show-size
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="1" class="text-right">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="error"
                              @click="fileRemove(i)"
                            >
                              <v-icon medium> mdi-file-remove </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar documento (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12">
                        <v-divider class="pb-1" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          fab
                          x-small
                          color="warning"
                          :loading="loading"
                          @click="fileAdd"
                        >
                          <v-icon> mdi-file-plus </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Agregar documento'" />
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="data.services.length == 0" cols="12">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'MÉDICO | BÚSQUEDA'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Tipo de especialidad"
                      v-model="service_search.specialty_type_id"
                      :items="specialty_types"
                      item-value="id"
                      :item-text="(i) => i.specialty_type"
                      :loading="specialty_types_loading"
                      dense
                    />
                  </v-col>
                  <v-col v-if="login.role_id == 1" cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Estado"
                      v-model="service_search.entity_id"
                      :items="entities"
                      item-value="id"
                      :item-text="(i) => i.entity"
                      :loading="entities_loading"
                      v-on:change="getMunicipalities"
                      dense
                    />
                  </v-col>
                  <v-col v-if="login.role_id == 1" cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Municipio"
                      v-model="service_search.municipality_id"
                      :items="municipalities"
                      item-value="id"
                      :item-text="(i) => i.municipality"
                      :loading="municipalities_loading"
                      dense
                    />
                  </v-col>
                  <v-col v-if="login.role_id == 1" cols="12" xs="12" md="3">
                    <v-text-field
                      label="C.P."
                      v-model="service_search.zip"
                      maxlength="5"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col v-if="login.role_id != 1" cols="12" xs="12" md="6">
                    <v-autocomplete
                      label="Hospital"
                      v-model="user_provider_id"
                      :items="user_providers"
                      item-value="id"
                      :item-text="
                        (v) =>
                          `${v.trade_name} (${v.entity}, ${v.municipality})`
                      "
                      :loading="user_providers_loading"
                      v-on:change="getMunicipalyEntity"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="
                      service_search.specialty_type_id &&
                      data.services.length == 0
                    "
                  >
                    <v-btn
                      block
                      color="info"
                      small
                      @click.prevent="serviceSearch"
                    >
                      <v-icon left> mdi-database-search </v-icon>
                      BUSCAR MÉDICO
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="data.services.length > 0" cols="12">
            <v-card tile :disabled="id ? true : false">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span
                    class="text-caption"
                    v-text="'SERVICIOS SELECCIONADOS'"
                  />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <div v-if="data.services.length > 0">
                  <v-row v-for="(v, i) in data.services" :key="i">
                    <v-col
                      cols="12"
                      v-if="v.active && v.doctor && v.consulting_room"
                    >
                      <v-card tile>
                        <v-toolbar elevation="1" dense>
                          <v-toolbar-title>
                            <span
                              class="text-caption"
                              v-text="`${v.id ? `C${v.id} | ` : ''}MÉDICO`"
                            />
                          </v-toolbar-title>
                          <v-spacer />
                          <v-toolbar-items>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  color="error"
                                  icon
                                  @click.prevent="serviceRemove(i)"
                                >
                                  <v-icon> mdi-close </v-icon>
                                </v-btn>
                              </template>
                              <span v-text="`ELIMINAR MÉDICO (${i + 1})`" />
                            </v-tooltip>
                          </v-toolbar-items>
                        </v-toolbar>
                        <v-spacer />
                        <v-card-text>
                          <v-row dense>
                            <v-col cols="12" xs="12" md="6">
                              <ViewData
                                label="Nombre"
                                :value="`${v.doctor.name} ${v.doctor.first_surname} ${v.doctor.second_surname}`"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Contacto"
                                :value="`${
                                  v.doctor.phone
                                    ? `TEL. ${v.doctor.phone} / `
                                    : ''
                                }${
                                  v.doctor.cell_phone
                                    ? `CEL. ${v.doctor.cell_phone} / `
                                    : ''
                                }${v.doctor.email ? v.doctor.email : ''}`"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <v-text-field
                                label="Monto"
                                v-model="v.benefits[0].amount"
                                type="number"
                                dense
                                :rules="rules.required"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Dirección"
                                :value="`${
                                  v.consulting_room.street
                                    ? v.consulting_room.street
                                    : ''
                                }${
                                  v.consulting_room.external_number
                                    ? ` ${v.consulting_room.external_number}`
                                    : ''
                                }${
                                  v.consulting_room.internal_number
                                    ? `, INT. ${v.consulting_room.internal_number}`
                                    : ''
                                }${
                                  v.consulting_room.suburb
                                    ? `, COL. ${v.consulting_room.suburb}`
                                    : ''
                                }`"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Municipio"
                                :value="v.consulting_room.municipality_name"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Estado"
                                :value="v.consulting_room.entity_name"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Código postal"
                                :value="v.consulting_room.zip"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                :label="'Especialidad'"
                                :value="v.doctor_specialty.name"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Cédula"
                                :value="v.doctor_specialty.license"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>No selecciono ningún servicio.</div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            class="text-right"
            v-if="data.services.length > 0"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  :color="id ? 'info' : 'success'"
                  class="mr-1"
                  v-on="on"
                  small
                  dark
                  fab
                  @click.prevent="formSubmit"
                  :loading="loading"
                >
                  <v-icon>
                    {{ id ? "mdi-pencil" : "mdi-plus" }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-text="id ? 'Editar' : 'Agregar'" />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog v-model="services_dialog" scrollable persistent max-width="1800">
      <v-card
        tile
        :loading="services_dialog_loading"
        :disabled="services_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Médicos encontrados </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="services_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" class="pt-3">
              <v-text-field
                v-model="table_doctors_search"
                append-icon="mdi-magnify"
                label="Buscar..."
                single-line
                hide-details
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-data-table
                v-if="services.length > 0"
                :headers="table_doctors_headers"
                :search="table_doctors_search"
                :items="services"
                :loading="services_dialog_loading"
                :items-per-page="-1"
                item-key="index"
                dense
              >
                <template v-slot:item.index="{ item }">
                  <b v-text="item.index" />
                </template>
                <template v-slot:item.id="{ item }">
                  {{ item.id }}
                  <span>
                    <v-icon
                      :color="
                        item.valid != null
                          ? item.valid
                            ? 'success'
                            : 'error'
                          : ''
                      "
                    >
                      mdi-{{
                        item.valid != null
                          ? item.valid
                            ? "check-"
                            : "alert-"
                          : ""
                      }}decagram
                    </v-icon>
                  </span>
                </template>
                <template v-slot:item.consulting_room_provider_name="{ item }">
                  {{
                    item.consulting_room_provider_name
                      ? item.consulting_room_provider_name
                      : "-"
                  }}
                </template>
                <template v-slot:item.amount="{ item }">
                  {{ numberFormat(item.amount) }}
                </template>
                <template v-slot:item.rs_services_pending="{ item }">
                  <span
                    v-if="item.rs_services_pending > 0"
                    class="orange--text"
                    v-text="item.rs_services_pending"
                  />
                  <span v-else v-text="'-'" />
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        small
                        color="warning"
                        @click="serviceData(item)"
                      >
                        <v-icon> mdi-check </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Seleccionar'" />
                  </v-tooltip>
                </template>
              </v-data-table>

              <!-- <v-simple-table dense v-if="services.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Nombre'" />
                      <th v-text="'Estado'" />
                      <th v-text="'Municipio'" />
                      <th v-text="'Dirección'" />
                      <th v-text="'C.P.'" />
                      <th v-text="'Hospital'" />
                      <th v-text="'Monto'" />
                      <th v-text="''" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, i) in services" :key="i">
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="v.name" />
                      <td v-text="v.entity_name" />
                      <td v-text="v.municipality_name" />
                      <td>
                        {{
                          (v.street ? v.street : "") +
                          " " +
                          (v.external_number ? v.external_number : "") +
                          (v.internal_number
                            ? " INT. " + v.internal_number
                            : "") +
                          (v.suburb ? ", COL. " + v.suburb : "")
                        }}
                      </td>
                      <td v-text="v.zip" />
                      <td
                        v-text="
                          v.consulting_room_provider_name
                            ? v.consulting_room_provider_name
                            : '-'
                        "
                      />
                      <td v-text="numberFormat(v.amount)" />
                      <td width="50px">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="warning"
                              @click="serviceData(v)"
                            >
                              <v-icon> mdi-check </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Seleccionar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table> -->
              <span v-else>
                {{
                  services_dialog_loading
                    ? "cargando..."
                    : "No se encontraron registros conincidentes con la busqueda"
                }}
              </span>
            </v-col>
            <v-col cols="12">
              <v-btn color="warning" x-small dark @click="doctorDialog">
                <v-icon left> mdi-plus </v-icon>
                Agregar Médico
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="doctor_dialog" scrollable persistent max-width="1200">
      <v-card
        tile
        :disabled="doctor_dialog_loading"
        :loading="doctor_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Agregar médico </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'DATOS'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_doctor_data"
                    lazy-validation
                  >
                    <v-row v-if="doctor_data" dense>
                      <v-col cols="12">
                        <b>
                          <i>
                            <span class="red--text">*</span>
                            Solo escribir nombre completo
                          </i>
                        </b>
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Nombre*"
                          v-model="doctor_data.name"
                          type="text"
                          :rules="rules.textRequired"
                          maxlength="50"
                          dense
                          counter
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="A. Paterno*"
                          v-model="doctor_data.first_surname"
                          type="text"
                          :rules="rules.textRequired"
                          maxlength="25"
                          dense
                          counter
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="A. Materno"
                          v-model="doctor_data.second_surname"
                          type="text"
                          :rules="rules.text"
                          maxlength="25"
                          dense
                          counter
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-text-field
                          label="RFC"
                          v-model="doctor_data.code_tax"
                          type="text"
                          maxlength="13"
                          dense
                          counter
                          :rules="rules.code_tax_sat_opt"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Consultorio | Hospital*"
                          v-model="doctor_data.provider_id"
                          :items="providers"
                          item-value="id"
                          :item-text="(v) => v.trade_name"
                          :loading="providers_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Especialidad*"
                          v-model="doctor_data.specialty_type_id"
                          :items="specialty_types"
                          item-value="id"
                          :item-text="(v) => v.specialty_type"
                          :loading="specialty_types_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-text-field
                          label="Cédula"
                          v-model="doctor_data.license"
                          type="text"
                          maxlength="15"
                          dense
                          counter
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="success"
                          @click.prevent="doctorDialogSubmit"
                          small
                        >
                          ACEPTAR
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="find_doctors_dialog"
      scrollable
      persistent
      max-width="1200"
    >
      <v-card
        tile
        :disabled="find_doctors_dialog_loading"
        :loading="find_doctors_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Médicos encontrados </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="find_doctors_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="find_doctors_dialog && find_doctors_data.length > 0">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'IDM'" />
                      <th v-text="'Nombre'" />
                      <th v-text="'Consultorios'" />
                      <th v-text="'Especialidades'" />
                      <th v-text="''" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, i) in find_doctors_data" :key="i">
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="v.id" />
                      <td>
                        {{
                          v.name +
                          " " +
                          v.first_surname +
                          " " +
                          v.second_surname
                        }}
                      </td>
                      <td>
                        <span
                          v-for="(consulting_room, j) in v.consulting_rooms"
                          :key="j"
                        >
                          {{
                            consulting_room.provider
                              ? consulting_room.provider.trade_name
                              : "*SIN HOSPITAL"
                          }}
                          ({{
                            consulting_room.entity_name +
                            " " +
                            consulting_room.municipality_name
                          }}) |
                        </span>
                      </td>
                      <td>
                        <span v-for="(specialty, k) in v.specialties" :key="k">
                          {{ specialty.name }} ({{
                            specialty.license != null && specialty.license != ""
                              ? specialty.license
                              : "NO REG."
                          }}) |
                        </span>
                      </td>
                      <td width="50px">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="warning"
                              @click="doctoAddConsultingRoom(v)"
                            >
                              <v-icon> mdi-check </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Agregar consultorio'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgAlert,
  msgConfirm,
  rules,
} from "../../control";
import { index, show, storeUpdate } from "../../requests/pageRequest";
import { catalog } from "../../requests/catalogRequest";
import ViewData from "../../components/ViewData.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    DatePicker,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      page_route: "caja_medicos",
      rs_service_id: parseInt(this.$route.params.rs_service_id),
      id: null,
      login: this.$store.getters.getLogin,
      rules: rules(),
      loading: true,
      data: {
        updated_by_id: this.$store.getters.getLogin.id,
        id: null,
        user_id: null,
        insurance_id: 2,
        insured: null,
        insured_id: null,
        name: "",
        first_surname: "",
        second_surname: "",
        enrollment: "",
        office_address: "",
        contractor: "",
        rs_type_id: 4,
        email: "",
        phone: "",
        detail: "",
        send_folio: "",
        phone_medical: false,
        phone_medical_entity_id: null,
        phone_medical_municipality_id: null,
        phone_medical_date: null,
        phone_medical_time: null,
        documents: [],
        services: [],
        accident: null,
        initial: null,
        preexisting: null,
        comment: "",
        icds: [],
      },
      service_search: {
        rs_service_type_id: 1,
        specialty_type_id: null,
        provider_type_id: null,
        entity_id: null,
        municipality_id: null,
        zip: "",
      },
      entities: [],
      entities_loading: true,
      municipalities: [],
      municipalities_loading: false,
      specialty_types: [],
      specialty_types_loading: true,
      services: [],
      services_dialog: false,
      services_dialog_loading: false,
      doctor_dialog: false,
      doctor_dialog_loading: false,
      doctor_data: null,
      providers: [],
      providers_loading: true,
      table_doctors_search: "",
      table_doctors_headers: [
        {
          text: "#",
          filterable: false,
          value: "index",
        },
        {
          text: "IDM",
          filterable: true,
          value: "id",
        },
        {
          text: "Nombre",
          filterable: true,
          value: "name",
        },
        {
          text: "Estado",
          filterable: true,
          value: "entity_name",
        },
        {
          text: "Municipio",
          filterable: true,
          value: "municipality_name",
        },
        {
          text: "Dirección",
          filterable: true,
          value: "adrress",
        },
        {
          text: "C.P.",
          filterable: true,
          value: "zip",
        },
        {
          text: "Hospital",
          filterable: true,
          value: "consulting_room_provider_name",
        },
        {
          text: "Monto",
          filterable: true,
          value: "amount",
        },
        {
          text: "Pend. cobro",
          filterable: true,
          value: "rs_services_pending",
        },
        { text: "", value: "action", sortable: false, width: "55px" },
      ],
      user_providers: [],
      user_providers_loading: true,
      user_provider_id: null,
      find_doctors_data: [],
      find_doctors_dialog: false,
      find_doctors_dialog_loading: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getRs() {
      this.data.user_id = this.login.id;

      catalog("entities", this.login.token).then((res) => {
        this.entities = res;

        this.entities.push({
          id: null,
          entity: "TODOS",
        });

        this.service_search.entities = null;

        this.municipalities.push({
          id: null,
          municipality: "TODOS",
        });

        this.service_search.municipality_id = null;

        this.entities_loading = false;
      });

      Axios.get(
        `${URL_API}/catalog?name=specialty_types&no_order=false`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.specialty_types = resp.data.data;
        this.specialty_types_loading = false;
      });
      if (this.login.role_id == 1) {
        Axios.get(
          `${URL_API}/providers/type/4`,
          headersToken(this.login.token)
        ).then((resp) => {
          this.providers = resp.data.data;
          this.providers_loading = false;
        });
      } else {
        Axios.get(
          URL_API + "/user/" + this.login.id + "/providers",
          headersToken(this.login.token)
        ).then((resp) => {
          this.providers = resp.data.data;
          this.providers_loading = false;
        });
      }

      if (this.id) {
        Axios.get(
          URL_API + `/rss/${this.id}/1`,
          headersToken(this.login.token)
        ).then((res) => {
          this.data = res.data.data;
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
    fileAdd() {
      this.data.documents.push({
        id: null,
        active: 1,
        document_id: null,
        description: "",
        file: null,
      });
    },
    fileRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            if (this.data.documents[i].id === null) {
              this.data.documents.splice(i, 1);
            } else {
              this.data.documents[i].active = false;
            }
          }
        });
    },
    getMunicipalities() {
      this.municipalities_loading = true;

      index(
        "municipalities/" + this.service_search.entity_id,
        this.login.token,
        []
      ).then((res) => {
        this.municipalities = res.data;

        this.municipalities.push({
          id: null,
          municipality: "TODOS",
        });

        this.service_search.municipality_id =
          this.data.phone_medical_municipality_id;
        this.municipalities_loading = false;
      });
    },
    serviceSearch() {
      this.services = [];
      this.services_dialog = true;
      this.services_dialog_loading = true;

      index("service_search", this.login.token, [
        {
          name: "rs_service_type_id",
          value: this.service_search.rs_service_type_id,
        },
        {
          name: "specialty_type_id",
          value: this.service_search.specialty_type_id,
        },
        {
          name: "provider_type_id",
          value: this.service_search.provider_type_id,
        },
        {
          name: "entity_id",
          value: this.service_search.entity_id,
        },
        {
          name: "municipality_id",
          value: this.service_search.municipality_id,
        },
        {
          name: "zip",
          value: this.service_search.zip,
        },
        {
          name: "contractor_id",
          value: this.data.insured ? this.data.insured.contractor_id : null,
        },
      ]).then((res) => {
        this.services = res.data;
        this.services_dialog_loading = false;
      });
    },
    serviceData(v) {
      this.$swal.fire(msgConfirm(`¿Confirma agregar MÉDICO?`)).then((res) => {
        if (res.isConfirmed) {
          let service_exist = false;
          service_exist = this.data.services.filter((i) => i.doctor_id == v.id);
          service_exist = service_exist.length === 0 ? false : true;

          if (!service_exist) {
            this.serviceCreate(v);
          } else {
            this.$swal
              .fire(
                msgConfirm(
                  "Este servicio ya ha sido agregado ¿Confirma agregarlo?"
                )
              )
              .then((res) => {
                if (res.isConfirmed) {
                  this.serviceCreate(v);
                }
              });
          }
        }
      });
    },
    serviceCreate(v) {
      this.services_dialog_loading = true;

      let service = {
        id: null,
        active: true,
        rs_service_type_id: this.service_search.rs_service_type_id,
        specialty_type_id: this.service_search.specialty_type_id,
        specialty_type: {},
        provider_type_id: this.service_search.provider_type_id,
        provider_type: {},
        entity_id: this.service_search.entity_id,
        entity: {},
        municipality_id: this.service_search.municipality_id,
        municipality: {},
        zip: this.service_search.zip,
        doctor_id: null,
        doctor: null,
        consulting_room_id: null,
        consulting_room: null,
        doctor_specialty: null,
        provider_id: null,
        provider: null,
        agreement: false,
        confirm_consultation:
          v && v.confirm_consultation ? v.confirm_consultation : 0,
        confirm_consultation_by_id: null,
        confirm_consultation_at: null,
        medical_revision: false,
        benefits: [],
        benefits_amount: 0,
        benefit_search: {
          code: "",
          description: "",
        },
        assured_amount: null,
      };

      service.doctor_id = v.id;
      service.consulting_room_id = v.consulting_room_id;
      this.doctorData(service);
    },
    doctorData(service) {
      show("doctors", this.login.token, service.doctor_id).then((res) => {
        service.doctor = res;

        service.consulting_room = service.doctor.consulting_rooms.filter(
          (i) => i.id === service.consulting_room_id
        );
        service.consulting_room = service.consulting_room[0];

        service.doctor_specialty = service.doctor.specialties.filter(
          (i) => i.specialty_type_id === service.specialty_type_id
        );
        service.doctor_specialty = service.doctor_specialty[0];

        service.benefits.push({
          id: null,
          code: null,
          description:
            `${service.doctor.name} ${service.doctor.first_surname} ${service.doctor.second_surname}`.trim(),
          coverage_type: null,
          revision: false,
          revision_by_id: null,
          revision_at: null,
          amount: service.doctor.consultation_amount,
          amount_original: service.doctor.consultation_amount,
          active: true,
          benefit_detail_id: null,
          coverage_type_id: null,
        });

        this.serviceAdd(service);
      });
    },
    serviceAdd(service) {
      this.data.services.push(service);
      this.services_dialog = false;
      this.services_dialog_loading = false;
    },
    serviceRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar servicio (${i + 1})?`))
        .then((res) => {
          if (res.isConfirmed) {
            if (this.data.services[i].id !== null) {
              this.data.services[i].active = false;
            } else {
              this.data.services.splice(i, 1);
            }
          }
        });
    },
    doctorDialog() {
      this.doctor_data = {
        name: "",
        first_surname: "",
        second_surname: "",
        code_tax: "",
        license: "",
        specialty_type_id: this.service_search.specialty_type_id,
        provider_id: null,
        validate_code_tax: true,
      };
      this.doctor_dialog_loading = false;
      this.doctor_dialog = true;
    },
    doctorDialogSubmit() {
      if (this.$refs.form_doctor_data.validate()) {
        this.doctor_dialog_loading = true;

        Axios.post(
          `${URL_API}/doctors/search/name`,
          this.doctor_data,
          headersToken(this.login.token)
        ).then((resp) => {
          this.find_doctors_data = resp.data.data;

          if (this.find_doctors_data.length == 0) {
            Axios.post(
              `${URL_API}/doctors/service/valid`,
              this.doctor_data,
              headersToken(this.login.token)
            ).then((resp) => {
              this.doctor_dialog_loading = false;

              this.$swal
                .fire(
                  msgConfirm(
                    `${
                      resp.data.success ? "" : resp.data.message + " "
                    }¿Confirma agregar al médico?`
                  )
                )
                .then((resp) => {
                  if (resp.isConfirmed) {
                    this.doctor_dialog_loading = true;

                    Axios.post(
                      `${URL_API}/doctors/service`,
                      this.doctor_data,
                      headersToken(this.login.token)
                    ).then((resp) => {
                      this.$swal.fire(
                        msgAlert(
                          resp.data.success ? "success" : "error",
                          resp.data.message
                        )
                      );

                      if (resp.data.success) {
                        this.doctor_dialog = false;
                        this.serviceSearch();
                      } else {
                        console.log(resp.data.message);
                      }

                      this.doctor_dialog_loading = false;
                    });
                  }
                });
            });
          } else {
            this.doctor_dialog_loading = false;
            this.find_doctors_dialog_loading = false;
            this.find_doctors_dialog = true;
          }
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    formSubmit() {
      if (this.data.email !== "" || this.data.phone !== "") {
        if (this.$refs.form_submit.validate()) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma la ${this.id ? "edición" : "creación"} del registro?`
              )
            )
            .then((res) => {
              if (res.isConfirmed) {
                this.loading = true;

                storeUpdate(
                  "rss",
                  this.login.token,
                  this.id,
                  this.data,
                  this.login.id
                ).then((res) => {
                  this.$swal.fire(
                    msgAlert(res.success ? "success" : "error", res.message)
                  );
                  if (res.success) {
                    this.$router.push({
                      name: `${this.page_route}${this.id ? ".detalle" : ""}`,
                      params: {
                        id: this.id,
                      },
                    });
                  } else {
                    console.log(res.data.message);
                  }
                });
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "Formulario invalido, revisa los detalles señalados"
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Se debe de registrar un Correo electrónico y/o Teléfono"
          )
        );
      }
    },
    doctoAddConsultingRoom(doctor) {
      this.$swal
        .fire(
          msgConfirm(
            "¿Confirma agregar el consultorio | Hospital al médico (" +
              doctor.id +
              ") " +
              doctor.name +
              " " +
              doctor.first_surname +
              " " +
              doctor.second_surname +
              "?"
          )
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.find_doctors_dialog_loading = true;

            Axios.post(
              `${URL_API}/doctors/consulting/room`,
              {
                doctor_id: doctor.id,
                provider_id: this.doctor_data.provider_id,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.find_doctors_dialog = false;
                this.doctor_dialog = false;
                this.serviceSearch();
              } else {
                console.log(res.data.message);
              }

              this.find_doctors_dialog_loading = false;
            });
          }
        });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getMunicipalyEntity() {
      const item = this.user_providers.find(
        (v) => v.id == this.user_provider_id
      );

      this.service_search.entity_id = item.entity_id;
      this.service_search.municipality_id = item.municipality_id;
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    if (this.login.role_id != 1) {
      Axios.get(
        URL_API + "/user/" + this.login.id + "/providers",
        headersToken(this.login.token)
      ).then((resp) => {
        this.user_providers = resp.data.data;

        if (this.user_providers.length == 1) {
          this.user_provider_id = this.user_providers[0].id;
          this.getMunicipalyEntity();
        }

        this.user_providers_loading = false;
      });
    }

    if (this.rs_service_id) {
      Axios.get(
        URL_API + `/rss/services/${this.rs_service_id}`,
        headersToken(this.login.token)
      ).then((resp) => {
        if (resp.data.success) {
          this.id = resp.data.data.rs_id;
          this.getRs();
        } else {
          console.log(resp.data.message);
        }
      });
    } else {
      this.getRs();
    }
  },
};
</script>